import React from "react"
import { Layout, SEO } from "../../components/structure"
import { Hero, Content, CallToAction, Button, IconTile, Grid, Column, BlueBlock, BlogCard, GrayBlock, SeatBlocker, SpeedPass,StayPlus } from "../../components/blocks"
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"
import { mq, brand, font } from "../../styles"
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import IconsList from "../../components/blocks/icons/Icons"



const ProductsPage = () => {


  const pageQuery = useStaticQuery(graphql`
    query {
      heroImg: file(base: { eq: "Plusgrade-Hero-Banner-Products.jpg" }) {
        ...FullHeroBackground
      }
      rightArrowIconWhite: file(base: {eq: "arrow-right.png"}) {
        ...ButtonIcon
      }
    AncilRevIconOne: file(base: { eq: "certified.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      AncilRevIconTwo: file(base: { eq: "guage.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      AncilRevIconThree: file(base: { eq: "revenueOpp.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      AncilRevIconFour: file(base: { eq: "noEntry.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      AncilRevIconFive: file(base: { eq: "bullseye.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      AncilRevIconSix: file(base: { eq: "barChartIncrease.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      AncilRevIconSeven: file(base: { eq: "loyalty-circles.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      HeroRightArrow: file(base: {eq: "plusgrade-blue-arrow.png"}) {
        childImageSharp {
            fluid(maxWidth: 500, webpQuality: 100){
                ...GatsbyImageSharpFluid_withWebp
            }
        }
      }
      premUpgradeIcon: file(base: { eq: "prem_upgrade_icon_orange.png" }) {
        childImageSharp {
            fixed(width: 50, height: 50, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
        }
      }

      
      mobileDevice: file(base: { eq: "Product-Premium-Upgrade.png" }) {
        childImageSharp {
            fixed(width: 230, height: 290, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
      }
    airButton: file(base: { eq: "airButton.png" }) {
      childImageSharp {
        fixed(width: 15, height: 15, webpQuality: 100){
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cruiseButton: file(base: { eq: "cruiseButton.png" }) {
      childImageSharp {
        fixed(width: 15, height: 15, webpQuality: 100){
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    railButton: file(base: { eq: "railButton.png" }) {
      childImageSharp {
        fixed(width: 15, height: 15, webpQuality: 100){
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    hospitalityButton: file(base: { eq: "hospitalityButton.png" }) {
      childImageSharp {
        fixed(width: 15, height: 15, webpQuality: 100){
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    speedPassProduct: file(base: { eq: "Product-Speed-Pass.png" }) {
      childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
      }
    }
    lcrProductImage: file(base: { eq: "Wyndham-LCR-Mobile.png" }) {
      childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
      }
    }
    StayExtendImage: file(base: { eq: "StayExtended-Mobile-2023.png" }) {
      childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
      }
    }
    StayPlusImage: file(base: { eq: "Resized%20-%20StayPlus.png" }) {
      childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
      }
    }
    loyaltyIcon: file(base: {eq: "handPlus.png"}){
      childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
      }
    }
    stayExtendIcon: file(base: {eq: "Icons_Product_hospitality_final_StayExtend.png"}){
      childImageSharp {
          fixed(width: 50, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
      }
    }
    platformIcon: file(base: {eq: "holdUp.png"}){
      childImageSharp {
          fixed(width: 60, height: 51, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
      }
    }
    rightArrowIcon: file(base: {eq: "arrow-right.png"}) {
        ...ButtonIcon
      }
    platformPartnershipDevice: file(base: { eq: "PlatformPartnershipDevice.png" }) {
      childImageSharp {
          fluid(maxWidth: 440, webpQuality: 100) {
              ...GatsbyImageSharpFluid_withWebp
          }
      }
  }


}`)

  const GetStartedContainer = styled.div`
  
  ${ font.imports.sherika }

   width: 95%;
   max-width: 1400px;
   margin: 40px auto;

   h3 {
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 48px;
    text-align: left;
    color: ${brand.colors.pg_blue};
    padding-right: 140px;

    @media only screen and ${mq.maxMd} {
      font-size: 32px;
      text-align: center;
      padding-right: 0;
    }
   }
   p{
    font-family: ${font.family.sherika};
    font-weight: 400;
    font-size: 26px;
    text-align: left;
    padding-right: 100px;

    @media only screen and ${mq.maxMd} {
      font-size: 20px;
      text-align: center;
      padding-right: 0;
    }
   }

   >span {
    @media only screen and ${mq.maxMd} {
      margin: auto;
      display: block;
      width: 300px;
    }
   }
  `;

  const AncillaryRevDetailContainer = styled.div`
  
  ${ font.imports.sherika }

  padding: 60px 0;

  h2 {
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 56px;
    color: ${brand.colors.pg_orange};
    text-align: left;
    width: 95%;
    max-width: 900px;
    margin: 0 auto 80px;
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  .info-container-outer {
    max-width: 900px;
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;

    @media only screen and ${mq.maxMd} {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .info-container {
        flex-basis: 46%;

        @media only screen and ${mq.maxMd} {
          
          max-width: 450px;
          width: 95%;
        }

        .content-con {
            h4 {
                font-family: ${font.family.sherika};
                font-weight: 700;
                font-size: 26px;
                color: ${brand.colors.pg_primary_dark};
                white-space: pre-wrap;
                @media only screen and ${mq.maxMd} {
                  text-align: center;
                }
            }

            p {
                font-family: ${font.family.sherika};
                font-weight: 400;
                font-size: 16px;
                color: ${brand.colors.pg_primary_dark};
                @media only screen and ${mq.maxMd} {
                  text-align: center;
                }
            }
        }

        .icon-con {
            margin-bottom: 20px;
            @media only screen and ${mq.maxMd} {
              text-align: center;
              margin: auto;
            }
        }
    }
  }
  `;

  const ProductsHeroContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 80px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2%;

  @media only screen and ${mq.maxMd} {
    flex-direction: column;
  }

  > div {
    flex-basis: 48%;
  }

  .graphic-con {
    width: 100%;
    max-width: 495px;
    height: 470px;

    @media only screen and ${mq.maxMd} {
      align-self: flex-start;
    }
    
    .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
    }
  }

  .title-con {
    h2 {
        white-space: pre-wrap;
        font-family: ${font.family.sherika};
        font-weight: 700;
        font-size: 80px;
        color: ${brand.colors.pg_blue};
        padding-top: 135px;
        @media only screen and ${mq.maxMd} {
          text-align: center;
          font-size: 60px;
          padding-top: 60px;
        }
    }
  }
  
  `;

  const ProductContainerPremUpgrade = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 95%;
    max-width: 1200px;
    margin: auto;
    padding: 80px 0 0;

    @media only screen and ${mq.maxMd} {
      flex-direction: column;
      align-items: center;
    }

    > div {
        flex-basis: 48%;
    }

    .graphic-con {
        .graphic-holder {
            width: 316px;
            height: 395px;
            float: right;
            clear: both;

            @media only screen and ${mq.maxMd} {
              float: none;
            }

            .gatsby-image-wrapper {
                width: 100% !important;
                height: 100% !important;
            }
        }   
    }

    .content-con {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media only screen and ${mq.maxMd} {
          align-items: center;
        }

        .copy > h2 {
            font-family: ${font.family.sherika};
            font-weight: 600;
            font-size: 36px;
            color: ${brand.colors.pg_primary_dark};
            margin: 20px 0;
            @media only screen and ${mq.maxMd} {
              text-align: center;
            }
        }

        .copy > p {
            font-family: ${font.family.sherika};
            font-weight: 400;
            font-size: 20px;
            line-height: 22px;
            color: ${brand.colors.pg_secondary_dark};
            max-width: 530px;
            @media only screen and ${mq.maxMd} {
              text-align: center;
            }
        }

        .button-con {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          margin-bottom: 35px;
          @media only screen and ${mq.maxMd} {
            align-items: center;
            justify-content: center;
          }

          @media only screen and (max-width: 550px) {
            flex-direction: column;
            width: 95%;
          }

          > a {
              flex-basis: 45%;
              background-color: ${brand.colors.pg_orange};
              width: 100px;
              height: 50px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              gap: 0 10px;
              padding: 10px;
              border-radius: 26px;
              font-family: ${font.family.sherika};
              font-weight: 700;
              font-size: 20px;
              color: #fff;

              @media only screen and (max-width: 550px) {
                width: 100%;
                justify-content: center;
                align-items: center;
              }
          }


      }
    }
  `;

  const ProductsContainerLCR = styled.div`
  
  ${ font.imports.sherika }
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0 2%;
    padding-top: 80px;

    @media only screen and ${mq.maxMd} {
      width: 95%;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }

    .left-con, .right-con {
      flex-basis: 48%;
    }

    .left-con {
      display: flex;
      justify-content: center;
      padding-right: 130px;
      @media only screen and ${mq.maxMd} {
        padding-right: 0;
      }
    }

    .right-con {
      .icon-con {

        @media only screen and ${mq.maxMd} {
          margin: auto;
          text-align: center;
        }
      }
      .copy {
        h2 {
          font-family: ${font.family.sherika};
          font-weight: 600;
          font-size: 36px;
          color: ${brand.colors.pg_primary_dark};
          white-space: pre-wrap;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }

        p {
          font-family: ${font.family.sherika};
          font-weight: 400;
          font-size: 20px;
          color: ${brand.colors.pg_secondary_dark};
          line-height: 22px;
          max-width: 450px;
          margin-bottom: 30px;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }
      }
      
      /*>span {
        display: block;

        a {
          width: auto;
        }
      }
      */
      span {
        margin: auto;

        @media only screen and (max-width: 500px) {
            width: 225px;
        }
      }
    }
  `;

  const ProductsContainerLPP = styled.div`
  
  ${ font.imports.sherika }
    width: 95%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0 2%;
    padding: 60px 0;

    @media only screen and ${mq.maxMd} {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      padding-bottom: 60px;
    }

    .left-con, .right-con {
      flex-basis: 48%;
    }

    .left-con {
      display: flex;
      justify-content: center;
      padding-left: 130px;
      @media only screen and ${mq.maxMd} {
        padding-left: 0;
        flex-basis: 180px;
        width: 95%;
        max-width: 450px;
      }

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
        overflow: visible !important;
      }
    }

    .right-con {

      @media only screen and ${mq.maxMd} {
        width: 95%;
        max-width: 450px;
        margin: auto;
      }
      
      .icon-con {
        margin: auto;
        text-align: center;
        @media only screen and ${mq.maxMd} {
          margin: auto;
          text-align: center;
        }
      }
      .copy {
        h2 {
          font-family: ${font.family.sherika};
          font-weight: 600;
          font-size: 36px;
          color: ${brand.colors.pg_primary_dark};
          white-space: pre-wrap;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }

        p {
          font-family: ${font.family.sherika};
          font-weight: 400;
          font-size: 20px;
          color: ${brand.colors.pg_secondary_dark};
          line-height: 22px;
          margin-bottom: 30px;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }
      }
      >span {
        @media only screen and ${mq.maxMd} {
          margin: auto;
          text-align: center;
          display: block;
          width: 300px;
        }
      }
    }
  `;
  return (
    <Layout>
      <SEO
        title={'Produits | Plusgrade'}
        description={'Augmentez vos revenus auxiliaires grâce à des solutions transparentes adaptés aux clients'}
        keywords={'products, services, solutions'}
        lang="fr"
      />
      <Hero 
        title={"Produits"}
        subtitle={''}
        image={pageQuery['heroImg']}
        fullBG
        imageStyle={{objectPosition: "right top"}}
      />

      <Content>
        <GetStartedContainer>
            <h3>Augmentez vos revenus auxiliaires grâce à des solutions transparentes adaptées aux clients</h3>
            <p>Les plus grandes marques de voyage dans les domaines de l'aérien, du rail, des croisières et de l'hôtellerie font confiance à nos produits pour offrir à leurs clients des expériences incroyables et des revenus à marge importante.</p>

            <Button destination={'/fr/contact/'} label='Commencer' icon={pageQuery['rightArrowIconWhite']} blue whiteText pill />
        </GetStartedContainer>
      </Content>

      <Content whiteBG noMargin>
        <AncillaryRevDetailContainer>
            <h2>Générez des revenus auxiliaires</h2>

            <div className="info-container-outer">

                <div className="info-container">
                    <div className="icon-con">
                        <GatsbyImage fixed={pageQuery['AncilRevIconOne'].childImageSharp.fixed} />
                    </div>
                    <div className="content-con">
                        <h4>Solutions en marque blanche</h4>
                        <p>Générez des expériences clients cinq étoiles, de marque complète.</p>
                    </div>
                </div>

                <div className="info-container">
                    <div className="icon-con">
                        <GatsbyImage fixed={pageQuery['AncilRevIconTwo'].childImageSharp.fixed} />
                    </div>
                    <div className="content-con">
                        <h4>Intégration rapide</h4>
                        <p>S'intègre parfaitement à vos systèmes existants en quelques semaines.</p>
                    </div>
                </div>

                <div className="info-container">
                    <div className="icon-con">
                        <GatsbyImage fixed={pageQuery['AncilRevIconThree'].childImageSharp.fixed} />
                    </div>
                    <div className="content-con">
                        <h4>Flux de revenus directs</h4>
                        <p>Gardez le contrôle en tant que marchand officiel.</p>
                    </div>
                </div>

                <div className="info-container">
                    <div className="icon-con">
                        <GatsbyImage fixed={pageQuery['AncilRevIconFour'].childImageSharp.fixed} />
                    </div>
                    <div className="content-con">
                        <h4>Sans coûts initiaux</h4>
                        <p>Profitez d’une structure commerciale sans risques.</p>
                    </div>
                </div>

                <div className="info-container">
                    <div className="icon-con">
                        <GatsbyImage fixed={pageQuery['AncilRevIconFive'].childImageSharp.fixed} />
                    </div>
                    <div className="content-con">
                        <h4>{'Technologie de ciblage et\ndécision intelligente '}</h4>
                        <p>Accès à l'intelligence collective de plus de 200 partenaires.</p>
                    </div>
                </div>

                <div className="info-container">
                    <div className="icon-con">
                        <GatsbyImage fixed={pageQuery['AncilRevIconSix'].childImageSharp.fixed} />
                    </div>
                    <div className="content-con">
                        <h4>Croissance prouvée du chiffre d'affaires d'une année à l'autre</h4>
                        <p>Maximisez vos profits grâce à des produits et des flux de revenus diversifiés.</p>
                    </div>
                </div>

                <div className="info-container">
                    <div className="icon-con">
                        <GatsbyImage fixed={pageQuery['AncilRevIconSeven'].childImageSharp.fixed} />
                    </div>
                    <div className="content-con">
                        <h4>Intégration du programme de fidélisation</h4>
                        <p>La seule entreprise à proposer des produits à revenus auxiliaires et des services de fidélisation sous un même toit. Proposez des points/miles comme option de paiement et intégrez les produits auxiliaires qui font le bonheur de vos clients.</p>
                    </div>
                </div>

            </div>
        </AncillaryRevDetailContainer>
      </Content>

      <Content noMargin>
        <ProductsHeroContainer>
            <div className="graphic-con">
                <GatsbyImage fluid={pageQuery['HeroRightArrow'].childImageSharp.fluid} imgStyle={{objectPosition: "right center"}}/>
            </div>
            <div className="title-con">
                <h2>{"Nos\nproduits"}</h2>
            </div>
        </ProductsHeroContainer>
      </Content>

      <Content secondaryLightBG noMargin>
        <ProductContainerPremUpgrade>
            <div className="content-con" id='premium-upgrade'>
                <div classname="icon-con">
                    <GatsbyImage fixed={pageQuery['premUpgradeIcon'].childImageSharp.fixed} imageStyle={{objectPosition: "center bottom"}} />
                </div>
                <div className="copy">
                    <h2>Surclassement Premium</h2>
                    <p>
                      Améliorez l'expérience du voyage en offrant aux passagers la possibilité de demander ou d'acheter un surclassement. Surclassement Premium est la solution de surclassement de nouvelle génération la plus largement adoptée dans le monde.
                    </p>
                </div>
                <div className="button-con">
                    <Link to={'/fr/industries/transport-aerien/'}><span className="button-icon-con"><GatsbyImage fixed={pageQuery['airButton'].childImageSharp.fixed} /></span><span className="button-label-con">Transport aérien</span></Link>
                    <Link to={'/fr/industries/hotellerie/'}><span className="button-icon-con"><GatsbyImage fixed={pageQuery['hospitalityButton'].childImageSharp.fixed} /></span><span className="button-label-con">Hôtellerie</span></Link>
                    <Link to={'/fr/industries/croisiere/'}><span className="button-icon-con"><GatsbyImage fixed={pageQuery['cruiseButton'].childImageSharp.fixed} /></span><span className="button-label-con">Croisière</span></Link>
                    <Link to={'/fr/industries/rail/'}><span className="button-icon-con"><GatsbyImage fixed={pageQuery['railButton'].childImageSharp.fixed} /></span><span className="button-label-con">Rail</span></Link>
                    

                </div>
            </div>
            <div className="graphic-con">
                <div className="graphic-holder">
                    <GatsbyImage fixed={pageQuery['mobileDevice'].childImageSharp.fixed} imgStyle={{top: '10%'}}/>
                </div>
            </div>
        </ProductContainerPremUpgrade>
      </Content>
      <Content whiteBG e2e id='dynamic-seat-blocker'>
        <SeatBlocker
            title={'SeatBlocker'}
            copy={'Offrez plus d\'espace en permettant aux passagers de bloquer le siège ou la rangée à côté d\'eux. <strong>SeatBlocker</strong> crée un flux à long terme de revenus auxiliaires dans votre cabine économique qui fera du gaspillage d\'inventaire une chose du passé.'}
            buttonLabel={'En savoir plus'}
            buttonDestination={'/fr/industries/transport-aerien/'}
        />
      </Content>

      <Content secondaryLightBG noMargin >
        <SpeedPass 
          copy={'Offrez aux passagers de tous les jours ce sentiment VIP en proposant aux voyageurs l’option d’avoir accès à l’enregistrement et l’embarquement prioritaires, et de traverser la sécurité en toute quiétude. <strong>SpeedPass</strong> exploite les intégrations existantes et les points de contact des clients pour monétiser leur passage à l’aéroport.'}
          buttonLabel={'En savoir plus'}
          graphicStyle = {{objectPosition: "center top", top: '10%'}}
          image={pageQuery['speedPassProduct']}
           id='speed-pass'
          buttonDest={'/fr/industries/transport-aerien/'}
          style={{marginTop: "-60px"}}
          productPage
          fr={true}
        />
      </Content>

      <Content whiteBG noMargin id='stay-extended'>
        <ProductsContainerLCR>
          <div className="left-con">
            <GatsbyImage fixed={pageQuery['StayExtendImage'].childImageSharp.fixed} imgStyle={{objectPosition: "center 25%", top: '10%'}} />
          </div>
          <div className="right-con">
            <div className="icon-con">
              <GatsbyImage fixed={pageQuery['stayExtendIcon'].childImageSharp.fixed} />
            </div>
            <div className="copy">
              <h2>{'StayExtend'}</h2>
              <p>Donnez à vos clients plus de flexibilité en leur offrant la possibilité d’arriver plus tôt, de prolonger leur séjour ou de payer pour un départ tardif. <strong>StayExtend</strong> permet de monétiser sans effort les changements de durée de séjour et de lutter contre les taux d’occupation trop faibles.</p>
            </div>

            <Button destination={'/fr/industries/hotellerie/stayextend'} label={'En savoir plus'}  target={'_blank'} rel={'noreferer'} orange whiteText pill />
          </div>
        </ProductsContainerLCR>
      </Content>


      <Content secondaryLightBG noMargin >
        <StayPlus 
          copy={'Débloquez un monde de services améliorés pour vos clients, en veillant à ce que chaque séjour soit mémorable. <strong>StayPlus</strong> vous permet de proposer à vos clients toute une série d’avantages, notamment des visites au spa, des réservations de restaurants, des locations d’équipements, et bien plus encore. '}
          buttonLabel={'En savoir plus'}
          graphicStyle = {{objectPosition: "center top", top: '10%'}}
          image={pageQuery['StayPlusImage']}
           id='speed-passx'
          buttonDest={'/fr/industries/hotellerie/stayplus'}
          style={{marginTop: "-60px"}}
          productPage
        />
      </Content>

      <Content whiteBG noMargin id='loyalty-currency-retailing'>
        <ProductsContainerLCR>
          <div className="left-con">
            <GatsbyImage fixed={pageQuery['lcrProductImage'].childImageSharp.fixed} imgStyle={{objectPosition: "center 25%", top: '10%'}} />
          </div>
          <div className="right-con">
            <div className="icon-con">
              <GatsbyImage fixed={pageQuery['loyaltyIcon'].childImageSharp.fixed} />
            </div>
            <div className="copy">
              <h2>{'Commercialisation de devise de fidélisation'}</h2>
              <p>Permettez aux membres des programmes de fidélisation d'en faire plus avec leurs points et leurs miles, en augmentant la satisfaction des clients et en générant des revenus à forte marge. Points, une société de Plusgrade, transforme les programmes de fidélisation singuliers axés sur les gains en moteurs de revenus multiproduits.</p>
            </div>

            <Button destination={'https://www.points.com'} label={'Visiter Points pour en savoir plus'} faIcon={IconsList.external} target={'_blank'} rel={'noreferer'} orange whiteText pill />
          </div>
        </ProductsContainerLCR>
      </Content>
      
      <Content secondaryLightBG e2e>
        <ProductsContainerLPP>
          <div className="left-con">
              <GatsbyImage fluid={pageQuery['platformPartnershipDevice'].childImageSharp.fluid} imgStyle={{objectPosition: "center bottom", top: "10%", overflow: "visible"}} />
            </div>
            <div className="right-con">
              <div className="icon-con">
                <GatsbyImage fixed={pageQuery['platformIcon'].childImageSharp.fixed} />
              </div>
              <div className="copy">
                <h2>{'Plateforme commerciale\nde fidélisation'}</h2>
                <p>La <strong>Loyalty Commerce Platform</strong> de Points, une société de Plusgrade, permet aux membres d'échanger, de gagner et de convertir leurs points/miles sur des produits auxiliaires de partis tiers ou à travers des collaborations avec d'autres marques. En connectant votre programme à un réseau de partenaires leaders du secteur, vous pouvez augmenter les revenus auxiliaires et étendre l'utilité de vos points/miles.</p>
              </div>

              <Button destination={'https://www.points.com'} label={'Visiter Points pour en savoir plus'} faIcon={IconsList.external} target={'_blank'} rel={'noreferer'} orange whiteText pill />
            </div>
        </ProductsContainerLPP>
      </Content>
      <CallToAction
          title={'Générez des revenus auxiliaires avec des expériences clients incroyables '}
          subtitle={'Contactez-nous aujourd\'hui pour en savoir plus'}
          > 
          <Button destination={'/fr/contact/demandez-une-demonstration/'} label={'Demander une démonstration'} icon={pageQuery['rightArrowIconBlue']} white blueText pill />
      </CallToAction>
    </Layout>
  )
}

export default ProductsPage
